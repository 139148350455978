import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import 'lib-flexible'
Vue.config.productionTip = false
import Vuex from 'vuex';
Vue.use(Vuex)
import './plugins/axios'
import config from './config/config.js';
import util from './config/util.js'
import resetFont from 'wx-font-reset';

resetFont();
const store = new Vuex.Store({
	state: {
		user: {
			token: ''
		},
		code: '',
		goodsList: [],
		checkToken: 0,
		inviteCode: ''
	},
	mutations: {
		setToken(state, token) {
			state.user.token = token
		},
		setUser(state, user) {
			state.user = user;
		},
		setCode(state, code) {
			state.code = code
		},
		clearCode(state) {
			state.code = ''
		},
		clearUser(state) {
			state.user = {}
		},
		setOpenId(state, openid) {
			state.user.openid = openid
		},
		setGoodsList(state, goodsList) {
			state.goodsList = goodsList
		},
		setCheckToken(state, checkToken) {
			state.checkToken = checkToken
		},
		setInviteCode(state, inviteCode) {
			state.inviteCode = inviteCode
		},
	}
})



router.beforeEach((to, from, next) => {
	if (localStorage.getItem("user") != null && localStorage.getItem("user")) {
		//存在登录信息就从localStorage中提取状态再传值给vuex中
		let user = JSON.parse(localStorage.getItem("user"))
		store.commit("setUser", user);
		if (!store.state.checkToken) {
			let params = new FormData()
			axios.post(config.requestUrl + '/front/user/getUserTokenInfo', params, {
				headers: {
					'Content-Type': 'multipart/form-data',
					'token': store.state.user.token
				}
			}).then(response => {
				store.commit('setCheckToken', 1)
				if (response.data.code == 0) {
					if (response.data.obj < 600) {
						localStorage.removeItem("user");
						store.commit("clearUser");
						store.commit("clearCode");
					}
				} else {
					localStorage.removeItem("user");
					store.commit("clearUser");
					store.commit("clearCode");
				}
				next();
			}, response => {
				next()
			})
		} else {
			next();
		}
	} else {
		store.commit("clearUser");
		next();
	}
})

//判断是否是微信浏览器的函数
const isWeiXin = function() {
	//window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
	var ua = window.navigator.userAgent.toLowerCase();
	// alert(JSON.stringify(ua))
	//通过正则表达式匹配ua中是否含有MicroMessenger字符串
	if (ua.match(/MicroMessenger/i) == 'micromessenger') {
		return true;
	} else {
		return false;
	}
}
const GetParam = function(url, code) {
	url = url + "";
	let regstr = "/(\\?|\\&)" + code + "=([^\\&]+)/";
	let reg = eval(regstr);
	//eval可以将 regstr字符串转换为 正则表达式
	let result = url.match(reg);
	if (result && result[2]) {
		return result[2];
	}
}


Vue.directive('real-img', async function(el, binding) { //指令名称为：real-img
	let imgURL = binding.value; //获取图片地址
	if (imgURL) {
		let exist = await imageIsExist(imgURL);
		if (exist) {
			el.setAttribute('src', imgURL);
		}
	}
})

/**
 * 检测图片是否存在
 * @param url
 */
let imageIsExist = function(url) {
	return new Promise((resolve) => {
		var img = new Image();
		img.onload = function() {
			if (this.complete == true) {
				resolve(true);
				img = null;
			}
		}
		img.onerror = function() {
			resolve(false);
			img = null;
		}
		img.src = url;
	})
}


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
