<template>
	<div class="container">
		<div class="login-ti">登录</div>
		<div class="flex-row align-center">
			<input type="number" @input="inputLoginMobile" oninput="if(value.length > 11)value = value.slice(0, 11)"
				maxlength="11" v-model="mobile" placeholder="输入手机号" class="phone-input">
			<div class="get-code" :class="canSend==0?'get-code-false':''" @click.stop="getVerifyCode()">{{msg}}</div>
		</div>
		<input type="number" oninput="if(value.length > 6)value = value.slice(0, 6)" v-model="code" maxlength="6"
			placeholder="输入验证码" class="code-input">
		<div class="submit-btn" :class="canLogin == 1 && code.length >=6 ?'send-code-btn-true':''" @click="doLogin()">确认
		</div>
		<div class="flex-row align-top xy-wrap">
			<div class="check " :class="arg==1?'checked':''" @click.stop="checkArg(arg==1?0:1)"></div>
			<div class="flex-row align-center flex-wrap xy-t">
				新用户登录默认同意注册此网站，相关<div class="xy-con" @click.stop="navAgreement(1)">《注册协议》</div>可点此查看。
			</div>
		</div>
		<div class="code-wrap flex-column" v-if="showVerify">
			<div class="code-title">请输入验证码</div>
			<img :src="base64code" @click.stop="getVerifyCode" alt="" class="code-img">
			<div class="code-tips">看不清？点击图片换一张</div>
			<div class="code-input-wrap">
				<input type="number" oninput="if(value.length > 11)value = value.slice(0, 11)" maxlength="11"
					v-model="verifyCode" placeholder="请输入验证码" />
			</div>
			<div class="send-code-btn" @click="sendCode()">确定</div>
		</div>
	</div>
</template>

<script>
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import {
		Toast,

	} from 'vant';
	import 'vant/lib/toast/index.css'

	export default {
		data() {
			return {
				showVerify: 0,
				mobile: '',
				code: '',
				time: 0,
				msg: '获取验证码',
				canSend: 0,
				sending: 0,
				logining: 0,
				interval: '',
				base64code: '',
				verifyKey: '',
				verifyCode: '',
				arg: 0,
				canLogin: 0
			}
		},
		methods: {
			doLogin() {
				const that = this;
				if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(that.mobile)) {
					Toast('请输入手机号')
					return;
				}
				console.log(that.code)
				console.log(that.code.length)
				if (that.code.length < 6) {
					Toast('请输入验证码')
					return;
				}
				if (that.arg != 1) {
					Toast('请阅读并同意注册协议')
					return;
				}
				if (!that.logining) {
					let params = new FormData()
					params.append('phone', that.mobile)
					params.append('verifyCode', that.code)
					// params.append('code', this.$store.state.code)
					params.append('openId', this.$store.state.user.openid ? this.$store.state.user.openid : '')
					that.logining = 1;
					Toast.loading({
						message: '登录中...',
						forbidClick: true,
						duration: 0,
					});
					this.axios.post(config.requestUrl + '/front/weChatLogin', params, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}).then(response => {

						if (response.data.code == 0) {
							Toast.success('登录成功');
							let user = response.data.obj;
							user.randName = util.randomString();
							this.$store.commit('setUser', user)

							this.$store.commit('clearCode')
							localStorage.setItem('user', JSON.stringify(user))
							that.logining = 0;
							this.$router.replace('/')
						} else if (response.data.code == -8) {
							this.doRegister();
						} else {
							Toast(response.data.msg);
						}
					}, response => {
						console.log(response)
						that.logining = 0;
						Toast('登录失败，请稍后重试');
					})
				}
			},
			doRegister() {
				const that = this;
				let params = new FormData()
				params.append('phone', that.mobile)
				params.append('verifyCode', that.code)
				params.append('idNo', '')
				params.append('name', '')
				params.append('nickName', '')
				params.append('openId', '')
				params.append('inviteCode', '')

				that.axios.post(config.requestUrl + '/front/doRegisterFromWeChat', params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(async response => {
					Toast.clear();
					that.logining = 0;
					if (response.data.code == 0) {
						that.logining = 0;
						let user = response.data.obj;
						user.randName = util.randomString();
						that.$store.commit('setUser', user)
						that.$store.commit('clearCode')
						localStorage.setItem('user', JSON.stringify(user))
						Toast.success('登录成功');
						this.$router.replace('/')
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					console.log(response)
					that.logining = 0;
					Toast('失败，请稍后重试');
				})
			},
			navAgreement(type) {
				this.$router.push({
					path: "/webview",
					query: {
						type
					}
				});
			},
			checkArg(arg) {
				this.arg = arg;
			},
			inputLoginMobile(e) {
				this.checkLoginCanSend();
			},
			checkLoginCanSend() {
				if ((/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.mobile)) && this.time == 0) {
					this.canSend = 1;
				}
				if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.mobile)) {
					this.canLogin = 1;
				} else {
					this.canLogin = 0
				}

			},
			getVerifyCode() {
				const that = this;
				if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(that.mobile)) {
					Toast('请输入手机号')
					return;
				}
				let params = new FormData()
				this.axios.post(config.requestUrl + '/search/getImage', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.showVerify = 1;
						that.verifyKey = response.data.obj.key;
						that.base64code = response.data.obj.data
						console.log(response)
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						that.$store.commit("clearUser");
						that.$store.commit("clearCode");
					}
					Toast.fail(response.data.msg);
				})
			},
			sendCode() {
				const that = this;
				if (that.verifyCode == '') {
					Toast('请输入验证码')
					return;
				}
				this.showVerify = 0;
				let params = new FormData()
				params.append('phoneEmail', that.mobile)
				params.append('smsType', 1)
				params.append('zone', 86)
				params.append('doType', 2)
				params.append('codeValue', that.verifyCode)
				params.append('key', that.verifyKey)
				if (!that.sending) {
					that.sending = 1;
					Toast.loading({
						message: '发送中...',
						forbidClick: true,
						duration: 0,
					});
					that.axios.post(config.requestUrl + '/ajax/sendCode', params, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}).then(response => {
						that.sending = 0;
						if (response.data.code == 0) {
							Toast.success('发送成功');
							that.time = 60;
							that.canSend = false;
							that.interval = setInterval(() => {
								if (that.time <= 0) {
									that.msg = '获取验证码'
									clearInterval(this.interval);
									that.checkLoginCanSend();
								} else {
									that.time = that.time - 1;
									that.msg = `${that.time}s`
								}
							}, 1000)
						} else {
							Toast(response.data.msg);
						}

					}, response => {
						console.log(response)
						that.sending = 0;
						Toast('获取失败，请稍后重试');
					})

				}
			}
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
		background-image: url(../assets/login-bg.png);
		background-size: 100%;
		background-repeat: round;
		overflow: scroll;
	}

	.login-ti {
		margin: 174px 0 63px 84px;
		font-size: 100px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.phone-input {
		width: 413px;
		height: 90px;
		line-height: 40px;
		margin: 0 10px 0 84px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 25px 32px;
		box-sizing: border-box;
	}

	.get-code {
		width: 159px;
		height: 90px;
		background: #FFFFFF;
		border-radius: 10px;
		text-align: center;
		line-height: 90px;
	}

	.code-input {
		width: 582px;
		height: 90px;
		line-height: 40px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 25px 32px;
		box-sizing: border-box;
		margin: 60px 0 0 84px;
	}

	.submit-btn {
		width: 582px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 40px;
		margin: 221px auto 60px;
		font-size: 32px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.25);
		text-align: center;
		line-height: 80px;
	}

	.xy-wrap {
		width: 582px;
		margin: auto;
	}

	.check {
		margin-top: 5px;
		width: 18px;
		height: 18px;
		border: 1px solid #FFFFFF;
		border-radius: 100px;
		margin-right: 5px;
		box-sizing: border-box;
		flex-shrink: 0;
	}

	.checked {
		background: #F7D400;

	}

	.xy-t {
		font-size: 22px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.xy-con {
		color: #FDD400;
	}

	/* 蒙层 */
	.verify-model {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		z-index: 1001;
	}

	.code-wrap {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 1002;
		background: #FFFFFF;
		width: 500px;
		height: max-content;
		border-radius: 10px;
		padding: 40px 30px;
		box-sizing: border-box;
	}

	.code-title {
		font-size: 30px;
		font-weight: bold;
		text-align: center;
	}

	.code-img {
		width: 400px;
		height: 200px;
		display: flex;
		margin: 20px auto 15px;
	}

	.code-tips {
		width: 100%;
		text-align: center;
		font-size: 22px;
		color: #999999;
	}

	.code-input-wrap {
		width: 440px;
		height: 100px;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
		padding-left: 30px;
		box-sizing: border-box;
		margin-top: 30px;
	}

	.code-input-wrap input {
		width: 380px;
		height: 94px;
		font-size: 26px;
		color: #000000;
		line-height: 40px;
		padding: 0 30px;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border: none;
		box-sizing: border-box;
	}

	.send-code-btn {
		margin-top: 80px;
		width: 440px;
		height: 80px;
		background: #6236FF;
		border-radius: 40px;
		font-size: 30px;
		color: #FFFFFF;
		letter-spacing: 2px;
		text-align: center;
		line-height: 80px;
	}

	.send-code-btn-true {
		color: #6236FF;
	}

	.get-code-false {
		color: rgba(0, 0, 0, 0.5);
	}
</style>
