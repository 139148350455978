<template>
	<div class="container" v-html="agreement"></div>
</template>

<script>
	import config from '../config/config.js';
	import {
		Toast
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import util from '../config/util.js';
	export default {
		data() {
			return {
				agreement: '',
				type: 1
			}
		},
		methods: {
			getUserAgreement() {
				let params = new FormData()
				params.append('type', this.type);
				this.axios.post(config.requestUrl + '/front/getUserAgreement', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.agreement = response.data.obj.userAgreementList[0].content
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
					}
					this.loading = 0;
				}, response => {
					this.loading = 0;
					Toast('获取失败，请稍后重试');
				})
			}
		},
		created() {
			const type = this.$route.query.type;
			if (!type) {
				this.$router.push('/')
			}
			this.type = type;
			this.getUserAgreement();
		},
		mounted() {
			util.wxConfig();
		}
	}
</script>

<style>
</style>
