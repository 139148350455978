<template>
	<div class="container">
		<div class="empty" v-if="isRequest && list.length ==0">暂无订单</div>
		<van-list v-model="loading" @load="getWaitPayOrderList" :finished="finished" finished-text=""
			:error.sync="error" error-text="请求失败，点击重新加载">
			<div class="order-wrap">
				<div class="order-item flex-column" v-for="(item,index) in list" v-bind:key="index">
					<div class="time">购买时间：{{item.createTime}}</div>
					<div class="flex-row con">
						<img :src="item.listPic" class="goods-img" alt="">
						<div class="other flex-row space-between">
							<div class="flex-column info">
								<div class="name">{{item.productName}}</div>
								<div class="label-wrap flex-row align-center">
									<div class="label1">{{item.fareTypeStr}}</div>
									<div class="label2">{{item.ticketName}}</div>
								</div>
							</div>
							<div class="price-wrap flex-row align-center">
								<div class="price-ti">实付款：</div>
								<div class="price">￥{{item.payAmount}}</div>
							</div>
						</div>
					</div>
					<div class="btn-wrap flex-row align-center flex-end">
						<div class="btn-false" v-if="item.orderStatus == 4">已核销</div>
						<div class="btn-tk" v-if="item.canReturn == 1" @click.stop="refundPrice(index)">
							退款</div>
						<div class="btn-tk" v-if="item.orderStatus == 1" @click.stop="pay(index)">
							支付</div>
						<div class="btn-hx" v-if="item.orderStatus ==2" @click.stop="navDetail(index)">选日期</div>
						<div class="btn-hx" v-if="item.orderStatus ==7" @click.stop="navDetail(index)">兑换码</div>
					</div>
				</div>
			</div>
		</van-list>
	</div>
</template>

<script>
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import {
		Toast,
		List,
		Dialog
	} from 'vant';
	import 'vant/lib/index.css';
	import Vue from 'vue';
	Vue.use(List);
	export default {
		data() {
			return {
				list: [],
				more: true,
				pageNum: 1,
				pageSize: 10,
				windowHeight: 0,
				offsetHeight: 0,
				date: '',
				show: false,
				loading: false,
				finished: false,
				error: false,
				form: '',
				timeout: '',
				isRequest: 0
			}
		},
		methods: {
			pay(index) {
				let ticket = this.list[index];
				
				if (ticket.payType == 6) {
					let payInfo = JSON.parse(ticket.unionQRPayInfo)
					this.$router.push({
						path: '/codesdpay',
						query: {
							orderId: ticket.id,
							type: 3,
							qrcode: payInfo.qrCode
						}
					})
				} else {
					this.$router.push({
						path: 'zfb',
						query: {
							orderId: ticket.id,
							token: this.$store.state.user.token,
						}
					})
				}
			},
			navDetail(index) {
				let ticket = this.list[index];
				this.$router.push({
					path: 'orderdetail',
					query: {
						id: ticket.id,
						productName: ticket.productName,
						orderStatus: ticket.orderStatus,
						ticketName: ticket.ticketName,
						fareTypeStr: ticket.fareTypeStr,
						listPic: ticket.listPic,
						quantity: ticket.surplusQuantity
					}
				})
			},
			refundPrice(index) {
				const that = this;
				Dialog.confirm({
						title: '退款提示',
						message: '退款三个工作日内到账，原路退回付款账户， 是否确认退款？',
						confirmButtonText: '确认退款',
						cancelButtonText: '取消退款',
						cancelButtonColor: '#cccccc',
						confirmButtonColor: '#000000'
					})
					.then(() => {
						Toast.loading({
							message: '退款中。。。',
							forbidClick: true,
							duration: 0,
						});
						let params = new FormData()
						params.append('orderId', that.list[index].id)
						that.axios.post(config.requestUrl + '/front/order/applyRefundByOrderId', params, {
							headers: {
								'Content-Type': 'multipart/form-data',
								'token': this.$store.state.user.token
							}
						}).then(response => {
							if (response.data.code == 0) {
								Toast.success('退款成功')
								this.list = [];
								this.pageNum = 1;
								this.getWaitPayOrderList();
							} else {
								let msg = response.data.msg;
								const str = "未获取到用户信息";
								const str2 = "登录已失效"
								if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
									localStorage.removeItem("user");
									that.$store.commit("clearUser");
									that.$store.commit("clearCode");
									setTimeout(() => {
										this.$router.push('/')
									}, 1200)
								}
								Toast(response.data.msg);
							}
							this.loading = 0;
						}, response => {
							this.loading = 0;
							Toast('获取失败，请稍后重试');
						})
					})
					.catch(() => {
						console.log('取消退出登录')
						// on cancel
					});
			},
			getWaitPayOrderList() {

				const that = this;
				let params = new FormData()
				params.append('pageNum', this.pageNum);
				params.append('pageSize', this.pageSize);

				this.axios.post(config.requestUrl + '/front/order/getWaitPayOrderList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let list = that.list;
						let newList = response.data.obj.list;
						let arr = [];
						let status = [1, 2, 4, 6, 7];
						for (let i in newList) {
							let createTime = newList[i].createTime.replace(/-/g, "/");
							let canReturn = 0;
							if (newList[i].orderStatus == 2 && util.compareOrderTime(createTime) == 0) {
								canReturn = 1;
							}
							newList[i].createTime = util.formatDate(createTime)
							newList[i].canReturn = canReturn;
							if (status.indexOf(newList[i].orderStatus) != -1) {
								arr.push(newList[i])
							}
						}
						if (response.data.obj.isLastPage) {
							this.finished = true;
							this.more = false;
						}
						list = list.concat(arr);
						this.pageNum = this.pageNum + 1;
						this.list = list;
						if (this.list.length == 0) {
							this.empty = true;
						}
						Toast.clear()
						this.$forceUpdate();
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
						this.error = true;
					}
					this.loading = false;
					this.isRequest = true;
				}, response => {
					this.loading = false;
					this.error = true;
					Toast('获取失败，请稍后重试');
				})
			},
		},
		mounted() {
			util.wxConfig();
			const token = this.$route.query.token;
			if (token) {
				this.$store.commit('setToken', token);
				let user = {}
				if (localStorage.getItem("user")) {
					user = JSON.parse(localStorage.getItem("user"))
					user.token = token;
				} else {
					user = {
						token
					}
				}
			}

			// var y = 30;
			// var money = 1000000;
			// var lr = 0.05 / 12 + 1;
			// var dk = 0.052 / 12 * 600000 + 0.059 / 12 * 400000;

			// for (let i = 0; i < 360; i++) {
			// 	money = money * lr - dk;
			// 	console.log(money)
			// }
		},
		destroyed() {
			clearInterval(this.timeout)
		}
	}
</script>

<style scoped="scoped">
	.container {
		background: #F4F4F4;
		min-height: 100%;
		overflow: visible;
		width: 100%;
		height: 100%;

		box-sizing: content-box;
	}

	.order-wrap {
		overflow: hidden;
	}

	.order-item {
		width: 710px;
		height: 280px;
		background: #FFFFFF;
		border-radius: 10px;
		margin: 20px auto auto;
	}

	.time {
		width: 100%;
		height: 74px;
		padding-left: 20px;
		box-sizing: border-box;
		font-size: 24px;
		font-weight: 400;
		line-height: 74px;
		color: #6D7278;
	}

	.con {
		width: 710px;
		height: 100px;
		padding-top: 30px;
		background: #FFFFFF;
		box-shadow: 0px -7px 13px 0px rgba(0, 0, 0, 0.05);
		border-radius: 10px;
	}

	.goods-img {
		width: 100px;
		height: 100px;
		margin-left: 20px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid rgba(0, 0, 0, 0.1);
	}

	.other {
		width: 560px;
	}

	.info {
		margin-left: 10px;
	}

	.btn-wrap {
		margin-top: 5px;
	}

	.name {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
	}

	.label-wrap {
		margin-top: 20px;
	}

	/* 	.label1 {
		width: 86px;
		height: 40px;
		background: rgba(0, 169, 250, 0.27);
		border-radius: 20px;
		font-size: 24px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.85);
		text-align: center;
		line-height: 40px;
	} */
	.label1 {
		width: 120px;
		height: 40px;
		background: rgba(247, 181, 0, 0.44);
		border-radius: 20px;
		text-align: center;
		line-height: 40px;
		font-size: 26px;
		font-weight: 400;
		color: #000000;
	}

	.label2 {
		padding: 2px 12px;
		margin-left: 13px;
		background: rgba(0, 169, 250, 0.27);
		border-radius: 20px;
		text-align: center;
		/* line-height: 40px; */
		font-size: 26px;
		font-weight: 400;
		color: #000000;
	}

	.price-ti {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
	}

	.price {
		font-size: 28px;
		font-weight: 600;
		color: #E02020
	}

	.btn-false {
		width: 150px;
		height: 50px;
		margin-right: 30px;
		background: rgba(0, 0, 0, 0.1);
		border-radius: 25px;
		font-size: 24px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
		text-align: center;
		line-height: 50px;
	}

	.btn-tk {
		width: 150px;
		height: 50px;
		margin-right: 30px;
		background: #6236FF;
		border-radius: 25px;
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		line-height: 50px;
	}

	.btn-hx {
		width: 150px;
		height: 50px;
		margin-right: 30px;
		background: rgba(255, 255, 255, 0.2);
		border-radius: 25px;
		border: 1px solid #6236FF;
		font-size: 24px;
		font-weight: 400;
		color: #6236FF;
		text-align: center;
		line-height: 50px;
		box-sizing: border-box;
	}

	.empty {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		font-size: 28px;
		width: 100%;
		height: max-content;
		text-align: center;
	}
</style>
