<template>
	<div class="container">
		<div class="img-wrap">
			<img class="goods-img" alt="" :src="info.mainPic">
			<div class="flex-row align-center order-wrap" @click.stop="navOrder()">
				<img src="../assets/icon-order.png" class="icon-order" alt="">
				<div class="order-text">我的订单</div>
			</div>
		</div>
		<div class="goods-con">
			<div class="goods-title">{{info.subtitle}}</div>
			<div class="goods-desc">{{info.description}}</div>
			<van-sticky :z-index='99'>
				<div class="tab-wrap flex-row align-center" ref="tab">
					<div class="tab-line"></div>
					<div class="tab flex-column align-center" @click.stop="changeTab(1)">
						<div class=" tab-ti" :class="tab == 1?'tab-ti-sel':''">商品详情</div>
						<div class="tab-l" :class="tab == 1?'tab-l-sel':''"></div>
					</div>
					<div class="tab-line"></div>
					<div class="tab flex-column align-center" @click.stop="changeTab(2)">
						<div class="tab-ti" :class="tab == 2?'tab-ti-sel':''">座位分布</div>
						<div class="tab-l" :class="tab == 2?'tab-l-sel':''"></div>
					</div>
					<div class="tab-line"></div>
				</div>
			</van-sticky>
			<div class="detail" ref="detail" v-html="info.details"></div>

		</div>
		<img ref="seatpic" :src="info.seatPic" class="seat-img" alt="">
		<!-- <img :src="info.seatPic" class="seat-img" alt=""> -->
		<div class="footer-wrap flex-row align-center">
			<div class="footer-left flex-column">
				<div class="ticket-info flex-row align-center">
					<div class="name">{{info.name}}</div>
					<div class="label org">{{info.fareTypeStr}}</div>
					<div class="label blue">{{info.ticketName}}</div>
				</div>
				<div class="price-wrap flex-row">
					<div class="for-price">售卖价格：</div>
					<div class="price">￥{{info.retailPrice}}</div>
				</div>
			</div>
			<div class="buy-btn" @click="showBuyModal()">我要购买</div>
		</div>
		<div class="model" v-if="confirmModal" @click.stop="closeBuyModal"></div>
		<div class="buy-confirm-wrap" v-if="confirmModal">
			<div class="bc-top flex-row align-center">
				<div class="bc-ti">选择购买数量</div>
				<div class="close-bc" @click.stop="closeBuyModal">关闭</div>
			</div>
			<div class="flex-row align-top tips-wrap">
				<img src="../assets/icon-tips.png" class="icon-tips" alt="">
				<div class="tips">您购买的电子凭证有效期截止2025年12月31日，付款七天内无理由退货，七天后可转让不可退货。</div>
			</div>

			<div class="num-con flex-row align-center justify-center">
				<div class="add" @click.stop="add()">+</div>
				<input type="text" onkeyup="value=value.replace(/[^\d]/g,'')"
					oninput="if(value.length > 2)value = value.slice(0,2)" class="num" v-model="num">
				<div class="reduce" @click.stop="reduce()">-</div>
			</div>
			<div class="addr-wrap flex-row align-center" @click.stop="tips()">
				<input type="text" v-model="addr" disabled placeholder="填入区块链地址享折扣" class="addr-input" >
				<img src="../assets/ques.png" class="ques-img" alt="">
				<!-- <input type="text" v-model="addr" :disabled="user!=''" placeholder="填入区块链地址享折扣" class="addr-input">
				<img src="../assets/ques.png" class="ques-img" alt="" @click.stop="showZkModal()"> -->
			</div>
			<div class="confirm-btn" @click.stop="confirmPay()">确认</div>
		</div>

		<div class="model" v-if="payModal" @click.stop="closePayModal()"></div>
		<div class="paytype-modal flex-column" v-if="payModal">
			<div class="paytype-top flex-row align-top">
				<img :src="info.listPic" class="pay-goodsimg" alt="">
				<div class="pt-info flex-column flex-wrap">
					<div class="pt-info-name">{{info.name}}</div>
					<div class="pt-label-wrap flex-row align-center">
						<div class="label1">{{info.fareTypeStr}}</div>
						<div class="label2">{{info.ticketName}}</div>
					</div>
				</div>
				<div class="price">￥{{price}}</div>
			</div>
			<div class="p-line"></div>
			<div class="pay-type-bot flex-column">
				<div class="pay-ti">支付渠道</div>
				<div class="pay-wrap">
					<div class="pay-item flex-row align-center" @click.stop="changePayType(6)">
						<img src="../assets/zfb_icon.png" alt="" class="pay-icon">
						<div class="pay-name">支付宝</div>
						<div class="pay-check " :class="payType ==6 ?'pay-checked':''"></div>
					</div>
					<div class="pay-item flex-row align-center" @click.stop="changePayType(8)">
						<img src="../assets/ysf.png" alt="" class="pay-icon">
						<div class="pay-name">银联云闪付</div>
						<div class="pay-check " :class="payType ==8 ?'pay-checked':''"></div>
					</div>
				</div>
				<div class="arg-wrap flex-row" @click.stop="navAgreement(3)">
					<div class="arg-desc">点击提交订单则视为同意</div>
					<div class="arg">《用户协议》</div>
				</div>
				<div class="pay-btn" @click.stop="createOrder()">确认</div>
			</div>
		</div>

		<div class="model" v-if="zkModal" @click.stop="closeZkModal()"></div>
		<div class="zk-model" v-if="zkModal">
			<div class="zk-item flex-row align-center space-between" v-for="(item,index) in yhList" v-bind:key="index">
				<div class="zk-name">{{index}}</div>
				<div class="zk-con flex-row align-center">
					<div class="zk-num">{{item}}</div>
					<div class="">折</div>
				</div>
			</div>
			<div class="zk-btn" @click.stop="closeZkModal()">关闭</div>
		</div>
	</div>
</template>

<script>
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import {
		Toast,
		Sticky,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css'
	import 'vant/lib/sticky/index.css'
	import 'vant/lib/dialog/index.css'
	import Vue from 'vue';
	Vue.use(Sticky);
	export default {
		data() {
			return {
				num: 1,
				confirmModal: 0,
				tab: 1,
				info: {},
				payType: 6,
				payModal: 0,
				price: 0,
				isBuying: 0,
				orderid: 0,
				addr: '',
				zkModal: 0,
				yhList: [],
				user:'',
				id:''
			}
		},
		mounted() {
			this.id = this.$route.query.id ? this.$route.query.id : '';
			if (!this.id) {
				this.id = localStorage.getItem('id')
			}
			localStorage.setItem('id', this.id)
			console.log(this.id)
			if(!parseInt(this.id)){
				Toast('请扫描二维码进入');
				return;
			}
			this.queryEnduePosterById();
			window.addEventListener('scroll', this.handleScroll)
		},
		activated() {
			if (this.$store.state.user.token) {
				this.queryUserAddress();
			}
		},
		methods: {
			tips(){
				Toast('敬请期待')
			},
			queryUserAddress() {
				const that = this;
				let params = new FormData()

				that.axios.post(config.requestUrl + '/front/user/queryUserAddress', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.user = response.data.obj;
						this.addr = response.data.obj
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.replace('/login')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			showZkModal() {
				const that = this;
				let params = new FormData()
				params.append('productEntryEndueID', that.id)

				that.axios.post(config.requestUrl + '/front/order/getDiscountByThirdBizId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.zkModal = 1;
						this.confirmModal = 0;
						this.yhList = response.data.obj
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.replace('/login')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})

			},
			closeZkModal() {
				this.zkModal = 0;
				this.confirmModal = 1;
			},
			navAgreement(type) {
				this.$router.push({
					path: "/webview",
					query: {
						type
					}
				});
			},
			navOrder() {
				if (!this.$store.state.user.token) {
					this.$router.push('/login')
					return;
				}
				this.$router.push('/orderlist');
			},
			createOrder() {
				const that = this;
				if (this.num <= 0) {
					Toast('请选择购买数量')
					return;
				}

				if (that.isBuying) {
					return
				}
				that.isBuying = 1
				Toast.loading({
					message: '正在下单...',
					forbidClick: true,
					duration: 0,
				});
				let params = new FormData()
				params.append('buyQuantity', that.num)
				params.append('originalAmount', that.info.retailPrice * that.num)
				params.append('thirdBizId', that.id)

				that.axios.post(config.requestUrl + '/front/order/createOrderByThirdBizId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					that.isBuying = 0
					if (response.data.code == 0) {
						if (this.payType == 6) {
							Toast.clear();
							// this.$router.push({
							// 	path: "/sdpay",
							// 	query: {
							// 		orderId: response.data.obj,
							// 		token: this.$store.state.user.token,
							// 		type: 1,
							// 		name: that.info.name
							// 	}
							// });
							this.$router.push({
								path: "/zfb",
								query: {
									orderId: response.data.obj,
									token: this.$store.state.user.token,
									type: 1,
									name: that.info.name
								}
							});
						} else if (this.payType == 7) {
							Toast.clear();
							this.$router.push({
								path: "/scansdpay",
								query: {
									orderId: response.data.obj,
									token: this.$store.state.user.token,
									type: 1,
									name: that.info.name
								}
							});
						} else if (this.payType == 8) {
							Toast.clear();
							this.$router.push({
								path: "/codesdpay",
								query: {
									orderId: response.data.obj,
									token: this.$store.state.user.token,
									type: 1,
									name: that.info.name
								}
							});
						}
						console.log(response)
					}else if(response.data.code == -2001){
						Toast(response.data.msg);
						setTimeout(()=>{
							this.$router.push('/orderlist')
						},1000)
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.replace('/login')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					that.isBuying = 0
					Toast('获取失败，请稍后重试');
				})
			},
			confirmPay() {
				const that = this;
				if (this.num <= 0) {
					Toast('请选择购买数量')
					return;
				}
				if (this.user == '' && this.addr == '') {
					this.price = this.num * this.info.retailPrice
					this.confirmModal = 0;
					this.payModal = 1;
				} else if ((this.user == '' && this.addr != '') || (this.user != '' && this.addr != this.user)) {
					let params = new FormData()
					params.append('address', that.addr)

					that.axios.post(config.requestUrl + '/front/user/linkUserInfo', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': this.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							this.queryUserAddress();
							that.getDiscountByUserId();
							console.log(response)
						} else {
							let msg = response.data.msg;
							const str = "未获取到用户信息";
							const str2 = "登录已失效"
							if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
								localStorage.removeItem("user");
								that.$store.commit("clearUser");
								that.$store.commit("clearCode");
								setTimeout(() => {
									this.$router.replace('/login')
								}, 1200)
							}
							Toast(response.data.msg);
						}
					}, response => {
						Toast('获取失败，请稍后重试');
					})
				} else if (this.user != '' && this.addr == this.user) {
					that.getDiscountByUserId();
				}


				// if (this.addr != '') {
				// 	let params = new FormData()
				// 	params.append('productEntryEndueID', that.id)

				// 	that.axios.post(config.requestUrl + '/front/order/getDiscountByUserId', params, {
				// 		headers: {
				// 			'Content-Type': 'multipart/form-data',
				// 			'token': this.$store.state.user.token
				// 		}
				// 	}).then(response => {
				// 		if (response.data.code == 0) {
				// 			this.zkModal = 1;
				// 			this.confirmModal = 0;
				// 			this.yhList = response.data.obj
				// 		} else {
				// 			let msg = response.data.msg;
				// 			const str = "未获取到用户信息";
				// 			const str2 = "登录已失效"
				// 			if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
				// 				localStorage.removeItem("user");
				// 				that.$store.commit("clearUser");
				// 				that.$store.commit("clearCode");
				// 				setTimeout(() => {
				// 					this.$router.replace('/login')
				// 				}, 1200)
				// 			}
				// 			Toast(response.data.msg);
				// 		}
				// 	}, response => {
				// 		Toast('获取失败，请稍后重试');
				// 	})
				// }
			},
			getDiscountByUserId() {
				const that = this;
				let params = new FormData()
				params.append('productEntryEndueID', that.id)

				that.axios.post(config.requestUrl + '/front/order/getDiscountByUserId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let zk = response.data.obj;
						let price = util.accMul(that.num, that.info.retailPrice)
						price = util.accMul(price, zk / 10)
						this.price = Math.floor(price * 100) / 100;
						this.confirmModal = 0;
						this.payModal = 1;
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.replace('/login')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			closePayModal() {
				this.payModal = 0
			},
			changePayType(payType) {
				this.payType = payType
			},
			add() {
				if (Math.abs(parseInt(this.num)) < 99) this.num = Math.abs(parseInt(this.num)) + 1
			},
			reduce() {
				if (Math.abs(parseInt(this.num)) > 1) this.num = Math.abs(parseInt(this.num)) - 1
			},
			handleScroll(e) {
				if (this.$refs.seatpic.getBoundingClientRect().top - this.$refs.tab.offsetHeight <= 10) {
					this.tab = 2;
				} else {
					this.tab = 1;
				}
			},
			changeTab(tab) {
				this.tab = tab;
				if (tab == 1) {
					window.scrollTo(0, this.$refs.detail.offsetTop - this.$refs.tab.offsetHeight)
				} else {
					window.scrollTo(0, this.$refs.seatpic.offsetTop - this.$refs.tab.offsetHeight)
				}
			},
			showBuyModal() {
				if (!this.$store.state.user.token) {
					this.$router.push('/login')
					return;
				}
				this.confirmModal = 1
			},
			closeBuyModal() {
				this.confirmModal = 0
			},
			queryEnduePosterById() {
				const that = this;
				let params = new FormData()
				params.append('productEntryEndueID', that.id)

				that.axios.post(config.makertUrl + '/front/endue/queryEnduePosterById', params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(response => {
					that.sending = 0;
					if (response.data.code == 0) {
						that.info = JSON.parse(response.data.obj)
						console.log(that.info)
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.img-wrap {
		position: relative;
	}

	.goods-img {
		width: 750px;
		height: 344px;
	}

	.order-wrap {
		width: 159px;
		height: 50px;
		background: #FFFFFF;
		border-radius: 25px;
		position: absolute;
		right: 30px;
		top: 20px;
	}

	.icon-order {
		width: 17px;
		height: 21px;
		margin: 0 8px 0 15px;
	}

	.order-text {
		font-size: 26px;
		font-weight: 400;
		color: #000000;
	}

	.goods-con {
		width: 670px;
		margin: auto;

	}

	.goods-title {
		width: 670px;
		margin: 30px auto 20px;
		font-size: 30px;
		font-weight: 600;
		color: #000000;
	}

	.goods-desc {
		width: 670px;
		margin: auto;
		font-size: 26px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
		margin-bottom: 39px;
	}

	.tab-wrap {

		background: #FFFFFF;
		height: 82px;
	}

	.tab-line {
		width: 120px;
		height: 1px;
		background: #F2F2F2;
	}

	.tab {
		margin: 0 30px;
		flex-shrink: 0;
	}

	.tab-ti {
		font-size: 28px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.25);
	}

	.tab-ti-sel {
		color: rgba(0, 0, 0, 0.85);
	}

	.tab-l {
		width: 70px;
		height: 4px;
		margin-top: 10px;
	}

	.tab-l-sel {
		background: #6236FF;
	}

	.footer-wrap {
		width: 750px;
		height: 170px;
		background: #FFFFFF;
		box-shadow: 0px -14px 19px 0px rgba(0, 0, 0, 0.07), inset 0px 1px 0px 0px #D6D6D6;
		border-radius: 20px 20px 0px 0px;
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 99;
	}

	.footer-left {
		width: 520px;
	}

	.ticket-info {}

	.name {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		margin-left: 30px;
		margin-right: 20px;
	}

	.org {
		background: #FFE396;

	}

	.blue {
		background: rgba(0, 169, 250, 0.27);

	}

	.label {
		padding: 8px 14px;
		border-radius: 20px;
		font-size: 24px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.85);
		margin-right: 10px;
	}

	.price-wrap {
		margin-left: 40px;
		margin-top: 40px;
	}

	.for-price {
		font-size: 30px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
	}

	.price {
		font-size: 30px;
		font-weight: 600;
		color: #FF0000;
	}

	.buy-btn {
		width: 180px;
		height: 126px;
		background-image: url(../assets/buy-bg.png);
		background-size: 230px 126px;
		padding-left: 50px;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		line-height: 126px;
	}

	.buy-confirm-wrap {
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 1001;
		background: #FFFFFF;
		padding-bottom: 18px;
	}

	.bc-top {
		width: 750px;
		height: 88px;
		background: #FFFFFF;
		border-radius: 20px 20px 0px 0px;
		position: relative;
	}

	.bc-ti {
		width: 750px;
		text-align: center;
	}

	.close-bc {
		position: absolute;
		top: 30px;
		right: 34px;
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.tips-wrap {
		width: 750px;
		background: rgba(247, 181, 0, 0.1);
		border-radius: 0px 0px 20px 20px;
		padding: 20px 40px;
		box-sizing: border-box;
	}

	.icon-tips {
		width: 21px;
		height: 24px;
		margin-right: 9px;
	}

	.tips {
		font-size: 24px;
		font-weight: 600;
		color: #FA6400;
	}

	.addr-wrap {
		width: 388px;
		height: 80px;
		margin: 60px auto 89px;
		background: rgba(201, 201, 201, 0.1);
		border-radius: 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
	}

	.addr-input {
		width: 318px;
		height: 80px;
		background: rgba(201, 201, 201, 0.1);
		border: none;
		font-size: 28px;
		font-weight: 400;
		padding: 0 18px;
		box-sizing: border-box;
	}

	.ques-img {
		width: 32px;
		height: 32px;
		margin-left: 21px;
	}

	.num-con {
		margin: 60px auto 0
	}

	.reduce {
		width: 130px;
		height: 80px;
		background: rgba(255, 255, 255, 0.1);
		border-radius: 10px 0px 0px 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		line-height: 80px;
		text-align: center;
		color: #000000;
		font-size: 35px;
		font-weight: bolder;
	}

	.num {
		width: 130px;
		height: 80px;
		background: rgba(0, 0, 0, 0.1);
		text-align: center;
		border: none;
	}

	.add {
		width: 130px;
		height: 80px;
		background: rgba(255, 255, 255, 0.1);
		border-radius: 10px 0px 0px 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		line-height: 80px;
		text-align: center;
		color: #000000;
		font-size: 35px;
		font-weight: bolder;
	}

	.confirm-btn {
		width: 590px;
		height: 80px;
		margin: auto;
		background: linear-gradient(180deg, #9B66FF 0%, #6236FF 100%);
		border-radius: 40px;
		text-align: center;
		font-size: 32px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 80px;
	}

	.detail {
		width: 670px;
		margin: 22px auto 0;
	}

	.detail>>>img {
		max-width: 100%;
	}

	.seat-img {
		max-width: 100%;
		height: auto;
		padding-bottom: 180px;
	}

	.paytype-modal {
		width: 670px;
		height: max-content;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1001;
		margin: auto;
		border-radius: 10px;
		overflow: hidden;
	}

	.paytype-top {
		width: 100%;
		height: 265px;
		padding: 66px 40px;

		box-sizing: border-box;
		background: linear-gradient(-45deg, transparent 10px, #fff 0) right,
			linear-gradient(45deg, transparent 10px, #fff 0) left;
		background-size: 60% 100%;
		background-repeat: no-repeat;

	}

	.paytype-border {
		width: 0;
		height: 0;
		border-width: 20px 10px;
		border-style: solid;
		border-color: #ffffff transparent transparent;
	}

	.sp {
		width: ;
	}

	.paytype-border2 {
		width: 0;
		height: 0;
		border-width: 20px 10px;
		border-style: solid;
		border-color: #ffffff transparent transparent;
	}

	.pay-goodsimg {
		width: 134px;
		height: 134px;
		border-radius: 4px;
	}

	.pt-info {
		margin-left: 20px;
		width: 300px;
	}

	.pt-info-name {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
	}

	.pt-label-wrap {
		margin-top: 20px;
	}

	.label1 {
		width: 120px;
		height: 40px;
		background: rgba(247, 181, 0, 0.44);
		border-radius: 20px;
		text-align: center;
		line-height: 40px;
		font-size: 26px;
		font-weight: 400;
		color: #000000;
	}

	.label2 {
		width: 120px;
		height: 40px;
		margin-left: 13px;
		background: rgba(250, 100, 0, 0.37);
		border-radius: 20px;
		text-align: center;
		line-height: 40px;
		font-size: 26px;
		font-weight: 400;
		color: #000000;
	}

	.price {
		font-size: 32px;
		font-weight: 600;
		color: #FF0000;
	}

	.p-line {
		margin: auto;
		width: 640px;
		height: 1px;
		background: #ECECEC;
	}

	.pay-type-bot {
		width: 100%;
		padding: 50px 40px;
		box-sizing: border-box;
		background: linear-gradient(225deg, transparent 10px, #fff 0) right,
			linear-gradient(-225deg, transparent 10px, #fff 0) left;
		background-size: 60% 100%;
		background-repeat: no-repeat;
	}

	.pay-ti {
		font-size: 26px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
	}

	.pay-item {
		margin-top: 40px;
	}

	.pay-wrap {
		padding-bottom: 96px;
		border-bottom: 1px solid #e3e3e3;
	}

	.pay-icon {
		width: 28px;
		height: 28px;
	}

	.pay-name {
		margin-left: 20px;
		width: 520px;
	}

	.pay-check {
		width: 22px;
		height: 22px;
		background: #D8D8D8;
		border-radius: 100%;
	}

	.pay-checked {
		background: #6236FF;

	}

	.arg-wrap {
		margin-top: 50px;
		font-size: 26px;
		font-weight: 400;
		color: #000000;
	}

	.arg {
		color: #6236FF;
	}

	.pay-btn {
		idth: 590px;
		height: 80px;
		margin-top: 38px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		line-height: 80px;
	}

	.zk-model {
		width: 616px;
		height: max-content;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1001;
		margin: auto;
		border-radius: 20px;
		overflow: scroll;
		padding: 8px 0 40px;
		box-sizing: border-box;
		background: #FFFFFF;
	}

	.zk-item {
		width: 570px;
		height: 87px;
		background: #FFF8E7;
		border-radius: 10px;
		margin: 20px auto auto;
		padding: 0 35px;
		box-sizing: border-box;
	}

	.zk-name {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
	}

	.zk-con {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
	}

	.zk-num {
		color: #FF0000;
		margin-right: 8px;
	}

	.zk-btn {
		margin: 73px auto auto;
		width: 530px;
		height: 80px;
		background: linear-gradient(180deg, #9B66FF 0%, #6236FF 100%);
		border-radius: 40px;
		font-size: 32px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		line-height: 80px;
	}
</style>
