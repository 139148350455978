<template>
	<div class="container">
		<div ref="qrCodeDiv" style="position: fixed;top: -9999px;left: -9999px;"></div>
		<div class="top-wrap" v-if="info.orderStatus == 2 || info.orderStatus == 8">
			<div class="flex-row align-center space-between top-info">
				<div class="flex-row align-center ">
					<div class="name">{{info.productName}}</div>
					<div class="label1">{{info.fareTypeStr}}</div>
					<div class="label2">{{info.ticketName}}</div>
				</div>
				<div class="flex-row align-center">
					<div class="line"></div>
					<div class="type">演出票</div>
				</div>
			</div>
			<div class="date-wrap flex-column">
				<div class="date-t-wrap flex-row align-center">
					<div class="date-ti">选择日期</div>
					<div class="date-sti">请注意：仅支持提前一天预约。</div>
				</div>
				<div class="date-items flex-row align-center flex-wrap">
					<template v-for="(item,index) in dateArr" >
						<div v-bind:key="index" class="date-item"
							:class="item.isNo == 1?'date-item-no':checkindex==index?'date-item-sel':''"
							@click.stop="checkDate(index)" v-if="index !=0">
							{{index == 0?'今天':index == 1?'明天':index == 2?'后天':''}}{{item.date}}
							<div class="ym" v-if="item.isNo==1">已满</div>
						</div>
					</template>
					<!-- <div v-for="(item,index) in dateArr" v-bind:key="index" class="date-item"
						:class="item.isNo == 1?'date-item-no':checkindex==index?'date-item-sel':''"
						@click.stop="checkDate(index)">
						明天{{item.date}}
						<div class="ym" v-if="item.isNo==1">已满</div>
					</div> -->
				</div>
			</div>

		</div>
		<div class="bot-wrap" v-if="info.orderStatus == 2 || info.orderStatus == 8">
			<div class="bot-top flex-row align-center">
				<div class="bot-ti">游客信息</div>
				<div class="bot-sti">姓名需和入园证件一致，手机号需接收短信</div>
			</div>
			<div class="bot-info-wrap flex-row align-center">
				<div class="bot-info-ti">姓名</div>
				<input type="text" v-model="name" class="bot-input" placeholder="必填，输入证件上的姓名">
			</div>
			<div class="bot-info-wrap flex-row align-center">
				<div class="bot-info-ti">手机号</div>
				<input type="text" ninput="if(value.length > 11)value = value.slice(0, 11)" maxlength="11"
					v-model="mobile" @input="inputMobile" class="bot-input" placeholder="必填，输入联系人手机号">
			</div>
			<div class="btn-sub" :class="checkindex>-1 && checkmobile==1 && name !=''?'btn-sub-true':''"
				@click.stop="doOrderWriteOff()">提交</div>
		</div>

		<div class="ticket-wrap" v-if="info.orderStatus ==4 || info.orderStatus == 7">
			<div class="ticket-ti">票务信息</div>
			<div class="goods-info-wrap flex-row align-center">
				<div class="ticket-name">{{info.productName}}</div>
				<div class="label1">{{info.fareTypeStr}}</div>
				<div class="label2">{{info.ticketName}}</div>
			</div>
			<div class="ticket-date-wrap flex-row align-center">
				<div class="ticket-date-ti">观看日期：</div>
				<div class="ticket-date">{{info.formatDate}} {{info.performTime}}</div>
			</div>
			<div class="ticket-date-wrap flex-row align-center">
				<div class="ticket-date-ti">兑换码：</div>
				<div class="ticket-date">{{info.thirdBizNo}}</div>
			</div>
			<div class="people-wrap flex-column justify-center">
				<div class="people-item flex-row align-center">
					<div class="pi-ti">游客姓名：</div>
					<div class="pi-con">{{info.name}}</div>
				</div>
				<div class="people-item mt30 flex-row align-center">
					<div class="pi-ti">游客电话：</div>
					<div class="pi-con">{{info.phone}}</div>
				</div>
			</div>
			<div class="code-wrap">
				<div class="code-ti">二维码</div>
				<div class="code-img-wrap flex-column align-center justify-center">
					<img :src="qrcodeImg" alt="" class="code">
					<!-- <div class="no">{{info.thirdBizNo}}</div> -->
				</div>
			</div>
			<img src="../assets/save-btn.png" class="save-btn" alt="" @click.stop="donwloadImg()">
		</div>
	</div>
</template>

<script>
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import QRCode from 'qrcodejs2';
	import {
		Toast,

	} from 'vant';
	import 'vant/lib/toast/index.css'
	export default {
		data() {
			return {
				info: {},
				dateArr: [],
				checkindex: -1,
				name: '',
				mobile: '',
				checkmobile: 0,
				qrcodeImg: ''
			}
		},
		mounted() {
			this.info = this.$route.query;
			if (this.info.orderStatus == 4 || this.info.orderStatus == 7) {
				this.getOrderDetails();
			}
			// this.dateArr = util.getTodayDateArr(1);
			this.dateArr = util.getDateArr(3);
		},
		methods: {
			doOrderWriteOff() {
				if (this.checkindex < 0) {
					Toast('请选择日期');
					return;
				}
				if (this.name == '') {
					Toast('请输入联系人姓名');
					return;
				}
				if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.mobile)) {
					Toast('请输入联系人手机号');
					return;
				}
				const that = this;
				let params = new FormData()
				params.append('name', that.name)
				params.append('orderId', that.info.id)
				params.append('orderSrcType', 1);
				params.append('performDate', that.dateArr[that.checkindex].formatDate)
				params.append('performTime', '08:00');
				params.append('phone', that.mobile);
				params.append('quantity', that.info.quantity);
				params.append('ticketName', that.info.ticketName);
				this.axios.post(config.requestUrl + '/front/order/doOrderWriteOff', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast('提交成功')
						this.info.orderStatus = 7;
						this.info.performDate = response.data.obj.performDate;
						let performDate = response.data.obj.performDate
						console.log(this.info.performDate.substring(0, 4))
						this.info.formatDate = performDate.substring(0, 4) + '-' + performDate.substring(4, 6) +
							'-' + performDate.substring(6, 8)
						this.info.performTime = response.data.obj.performTime;
						this.info.name = response.data.obj.name;
						this.info.phone = response.data.obj.phone;
						this.info.thirdBizNo = response.data.obj.thirdBizNo;
						console.log(this.info)
						console.log(response.data.obj.thirdBizNo)
						let qrcode = new QRCode(that.$refs.qrCodeDiv, {
							text: response.data.obj.thirdBizNo,
							width: 200,
							height: 200,
							colorDark: "#000000", //二维码颜色
							colorLight: "#FFFFFF", //二维码背景色
							correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
						})
						let canvas = qrcode._el.querySelector(
							"canvas"); //获取生成二维码中的canvas，并将canvas转换成base64
						that.qrcodeImg = canvas.toDataURL("image/png");
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('登录失败，请稍后重试');
				})

			},
			inputMobile() {
				if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.mobile)) {
					this.checkmobile = 1;
				} else {
					this.checkmobile = 0;
				}
			},
			checkDate(index) {
				if (this.dateArr[index].isNo == 1) return;
				this.checkindex = index;
				const that = this;
				let params = new FormData()
				params.append('orderId', that.info.id)
				params.append('performDate', that.dateArr[index].formatDate)
				if (that.info.productName == '敦煌盛典') {
					// params.append('performTime', '13:00')
					params.append('performTime', '21:00')
				} else if (that.info.productName == '那拉提所') {
					params.append('performTime', '21:00')
				}

				this.axios.post(config.requestUrl + '/front/order/queryOrderDateInventory', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (response.data.obj.count <= 0) {
							that.dateArr[index].isNo = 1;
							that.checkindex = -1;
						}
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('登录失败，请稍后重试');
				})
			},
			getOrderDetails() {
				const that = this;
				let params = new FormData()
				params.append('orderId', that.info.id)

				this.axios.post(config.requestUrl + '/front/order/getOrderDetails', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.info.performDate = response.data.obj.performDate;
						let performDate = response.data.obj.performDate
						console.log(this.info.performDate.substring(0, 4))
						this.info.formatDate = performDate.substring(0, 4) + '-' + performDate.substring(4, 6) +
							'-' + performDate.substring(6, 8)
						this.info.performTime = response.data.obj.performTime;
						this.info.name = response.data.obj.name;
						this.info.phone = response.data.obj.phone;
						this.info.thirdBizNo = response.data.obj.thirdBizNo;
						let qrcode = new QRCode(that.$refs.qrCodeDiv, {
							text: response.data.obj.thirdBizNo,
							width: 200,
							height: 200,
							colorDark: "#000000", //二维码颜色
							colorLight: "#FFFFFF", //二维码背景色
							correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
						})
						let canvas = qrcode._el.querySelector(
							"canvas"); //获取生成二维码中的canvas，并将canvas转换成base64
						that.qrcodeImg = canvas.toDataURL("image/png");
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					that.logining = 0;
					Toast('登录失败，请稍后重试');
				})
			},
			donwloadImg() {
				if (util.isWx()) {
					Toast.success('请长按图片保存')
				} else {
					var aLink = document.createElement('a');
					var blob = this.base64ToBlob(this.qrcodeImg); //new Blob([content]);
					var evt = document.createEvent("HTMLEvents");
					evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
					aLink.download = 'ticket' + Date.parse(new Date()) + '.png';
					aLink.href = URL.createObjectURL(blob);
					// aLink.dispatchEvent(evt);
					aLink.click()
				}
			},
			base64ToBlob(code) {
				var parts = code.split(';base64,');
				var contentType = parts[0].split(':')[1];
				var raw = window.atob(parts[1]);
				var rawLength = raw.length;

				var uInt8Array = new Uint8Array(rawLength);

				for (var i = 0; i < rawLength; ++i) {
					uInt8Array[i] = raw.charCodeAt(i);
				}
				return new Blob([uInt8Array], {
					type: contentType
				});
			}
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		min-height: 100%;
		padding-bottom: 30px;
		background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.69) 67%, rgba(0, 0, 0, 0.31) 100%);
		overflow: scroll;
	}

	.top-wrap {
		width: 690px;
		background: #FFFFFF;
		border-radius: 20px;
		overflow: hidden;
		margin: 20px auto;
	}

	.top-info {
		width: 690px;
		height: 110px;
		background: #FFFFFF;
		box-shadow: 0px 5px 8px 0px rgba(212, 212, 212, 0.28);
		border-radius: 20px 20px 0px 0px;
	}

	.name {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		margin-left: 26px;
	}

	.label1 {
		width: 100px;
		height: 40px;
		background: #FFE396;
		border-radius: 20px;
		font-size: 24px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.85);
		text-align: center;
		line-height: 40px;
		margin: 0 10px;
	}

	.label2 {
		padding: 2px 10px;
		/* width: 86px; */
		/* height: 40px; */
		background: rgba(0, 169, 250, 0.27);
		border-radius: 20px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.85);
		text-align: center;
		line-height: 40px;
	}

	.line {
		width: 3px;
		height: 30px;
		background: rgba(0, 0, 0, 0.25);
		margin-right: 50px
	}

	.type {
		font-size: 28px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
		margin-right: 60px;
	}

	.date-t-wrap {
		margin: 30px 40px;
	}

	.date-ti {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		margin-right: 10px;
	}

	.date-sti {
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
	}

	.date-items {
		width: 640px;
		margin: auto;
		padding-bottom: 40px;
	}

	.date-item {
		width: 191px;
		height: 80px;
		background: rgba(99, 58, 244, 0.04);
		border-radius: 10px;
		margin: 0 10px 20px;
		text-align: center;
		line-height: 80px;
		font-size: 28px;
		font-weight: 400;
		color: #311C66;
	}

	.date-item-sel {
		background: #6236FF;
		color: #FFFFFF;
	}

	.date-item-no {
		background: #F5F5F5;
		color: #2E1F62;
		position: relative;
	}

	.ym {
		padding: 4px 10px;
		border-radius: 10px 10px 10px 0px;
		background: #F7B500;
		position: absolute;
		top: -8px;
		left: 0;
		font-size: 20px;
		font-weight: 600;
		color: #000000;
		line-height: 1;
	}

	.bot-wrap {
		width: 690px;
		margin: auto;
		background: #FFFFFF;
		border-radius: 20px;
		padding-bottom: 60px;
	}

	.bot-top {
		width: 690px;
		height: 110px;
		background: #FFFFFF;
		box-shadow: 0px 5px 8px 0px rgba(212, 212, 212, 0.28);
		border-radius: 20px 20px 0px 0px;
	}

	.bot-ti {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		margin: auto 17px auto 40px;
	}

	.bot-sti {
		font-size: 24px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
	}

	.bot-info-wrap {
		width: 611px;
		height: 90px;
		margin: auto;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.bot-info-ti {
		font-size: 28px;
		font-weight: 400;
		color: #000000;
		width: 153px;
	}

	.bot-input {
		font-size: 28px;
		border: none;

	}

	.btn-sub {
		width: 590px;
		height: 80px;
		margin: 348px auto 0;
		background: rgba(0, 0, 0, 0.25);
		border-radius: 40px;
		text-align: center;
		line-height: 80px;
		font-size: 32px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.btn-sub-true {
		background: linear-gradient(180deg, #9B66FF 0%, #6236FF 100%);
	}

	.ticket-wrap {
		width: 690px;
		margin: 20px auto 0;
		background: #FFFFFF;
		border-radius: 20px;
		padding-bottom: 30px;
	}

	.ticket-ti {
		width: 690px;
		height: 100px;
		background: #FFFFFF;
		box-shadow: 0px 5px 8px 0px rgba(212, 212, 212, 0.28);
		border-radius: 20px 20px 0px 0px;
		text-align: center;
		line-height: 100px;
		font-size: 28px;
		font-weight: 600;
		color: #000000;
	}

	.goods-info-wrap {
		width: 611px;
		height: 119px;
		margin: auto;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.ticket-name {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
	}

	.ticket-date-wrap {
		width: 611px;
		height: 119px;
		margin: auto;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.ticket-date-ti {
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.ticket-date {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		margin-left: 30px;
	}

	.people-wrap {
		width: 611px;
		height: 174px;
		margin: auto;
	}

	.people-item {}

	.mt30 {
		margin-top: 30px;
	}

	.pi-ti {
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.pi-con {
		margin-left: 30px;
		font-size: 28px;
		font-weight: 600;
		color: #000000;
	}

	.code-wrap {
		width: 690px;
		height: 690px;
		background: #EEE9FF;
	}

	.code-ti {
		width: 690px;
		height: 111px;
		text-align: center;
		line-height: 111px;
		font-size: 28px;
		font-weight: 600;
		color: #000000;
	}

	.code-img-wrap {
		width: 500px;
		height: 500px;
		/* height: 525px; */
		margin: auto;
		background: #FFFFFF;
		box-shadow: 0px 2px 27px 0px rgba(174, 169, 255, 0.5);
		border-radius: 20px;
	}

	.code {
		width: 410px;
		height: 410px;
	}

	.save-btn {
		display: block;
		width: 321px;
		height: 128px;
		margin: 30px auto 0;
	}

	.no {
		margin-top: 25px;
		font-size: 28px;
		font-weight: 600;
		color: #000000;
	}
</style>
