//演示环境
var requestUrl, imgUrl, authHost, makertUrl, qrcodeHost, balanceUrl;
let env = 1;

if (env == 0) {
	requestUrl = 'http://81.69.172.13:8082/'
	makertUrl='http://www.allnfts.cn'
	authHost = 'http://testticket.metacbc.cn';
	qrcodeHost = 'http://test.allnfts.cn/'
	balanceUrl = 'http://test.allnfts.cn/balance'
} else {
	//线上环境
	requestUrl = 'https://mall.allnfts.cn';
	makertUrl= 'https://nft.allnfts.cn'
	imgUrl = 'https://ipfs.allnfts.cn/ipfs/';
	authHost = 'https://shop.metacbc.cn';
	qrcodeHost = 'https://wx.allnfts.cn/'
	balanceUrl = 'https://wx.allnfts.cn/balance'
}

//固定参数
const appId = 'wx23406d34d48c253c';
const host = authHost + '/';
const authUrl = 'https://auth.allnfts.cn'
export default {
	appId,
	requestUrl,
	makertUrl,
	imgUrl,
	authHost,
	host,
	authUrl,
	qrcodeHost,
	balanceUrl
}
