<template>
	<div id="app">
		<keep-alive>
			<router-view :key="key" v-if="$route.meta.keepAlive" />
		</keep-alive>
		<router-view :key="key" v-if="!$route.meta.keepAlive" />
	</div>
</template>
<script>
	export default {
		name: 'myApp',
		computed: {
			key() {
				return this.$route.fullPath
			}
		}
	}
</script>
<style>
	html {
		margin: 0 !important;
		padding: 0 !important;
	}

	body {
		margin: 0 !important;
		padding: 0 !important;
		-webkit-text-size-adjust: 100% !important;
		text-size-adjust: 100% !important;
		-moz-text-size-adjust: 100% !important;
	}

	html,
	body,
	#app {
		height: 100%;
	}

	.flex-row {
		display: flex;
		flex-direction: row;
	}

	.flex-column {
		display: flex;
		flex-direction: column;
	}

	.align-center {
		align-items: center;
	}

	.align-top {
		align-items: flex-start;
	}

	.align-end {
		align-items: flex-end;
	}

	.space-between {
		justify-content: space-between;
	}

	.space-around {
		justify-content: space-around;
	}

	.justify-center {
		justify-content: center;
	}

	.justify-end {
		justify-content: flex-end;
	}

	.flex-wrap {
		flex-wrap: wrap;
	}

	.relative {
		position: relative;
	}

	.flex-end {
		justify-content: flex-end;
	}

	/*按钮样式开始  */

	button {
		margin: 0;
		padding: 0;
		border: none;
		background: none;
		border-radius: none;
	}

	button::after {
		content: none;
	}

	button[plain] {
		border: none;
	}

	/*按钮样式结束  */

	img {
		vertical-align: middle;
	}

	/* 多行溢出 */

	.more-ellipsis {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}

	/* 蒙层 */
	.model {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		z-index: 1000;
	}

	/* 导航栏标题开始 */
	.nav {
		height: 88px;
		background: #000000;
		position: relative;
	}

	.nav-title-bg {
		width: 222px;
		height: 20px;
		background: linear-gradient(270deg, rgba(71, 19, 234, 0) 0%, #3E00FF 50%, rgba(60, 1, 243, 0) 100%);
		margin-top: 12px;
	}

	.nav-title {
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 88px;
		position: absolute;
		top: 0;
		left: 0;
		width: 750px;
		height: 88px;
		text-align: center;
	}

</style>
