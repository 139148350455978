import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue';
import detail from '../views/detail.vue';
import webview from '../views/webview.vue';
import sdpay from '../views/sdpay.vue';
import scansdpay from '../views/scansdpay.vue';
import codesdpay from '../views/codesdpay.vue';
import orderlist from '../views/orderlist.vue';
import orderdetail from '../views/orderdetail.vue';
import zfb from '../views/zfb.vue';
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const routes = [
	{
		path: '/',
		name: 'detail',
		component: detail,
		meta: {
			keepAlive: true
		}
	},
	{
			path: '/login',
			name: 'login',
			component: login,
			meta: {
				keepAlive: false
			}
		},
	{
		path: '/webview',
		name: 'webview',
		component: webview,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/sdpay',
		name: 'sdpay',
		component: sdpay,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/scansdpay',
		name: 'scansdpay',
		component: scansdpay,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/codesdpay',
		name: 'codesdpay',
		component: codesdpay,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/orderlist',
		name: 'orderlist',
		component: orderlist,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/orderdetail',
		name: 'orderdetail',
		component: orderdetail,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/zfb',
		name: 'zfb',
		component: zfb,
		meta: {
			keepAlive: false
		}
	},
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router
